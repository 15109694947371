
import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { linkDeviceWithOperator, getOperators } from '@/services/axios';

@Component({
  components: {},
})
export default class DevicesView extends Vue {
  @State opId!: number;

  message: string = '';
  name: string = '';
  operators: any[] = [];
  selectedOperator: number = this.opId || 0;

  get key(): any {
    return this.$route.query.key;
  }

  submitKey() {
    if (!this.key || !this.selectedOperator) return;
    linkDeviceWithOperator(this.selectedOperator, this.key, this.name)
      .then(res => {
        alert('Linked successfully');
        this.message = 'Linked successfully';
      })
      .catch(err => {
        // eslint-disable-next-line
        console.error(err);
        alert("Can't link device: " + JSON.stringify(err));
        this.message = "Can't link device: " + JSON.stringify(err);
      });
  }

  mounted() {
    getOperators()
      .then(res => (this.operators = res.data))
      .then(() => this.operators.sort((a, b) => a.op_id - b.op_id));
  }
}
